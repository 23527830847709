<template>
    <section class="dashboard-activities-index-page">
        <NuxtLink
            no-prefetch
            v-for="(tab, index) in mainTabs"
            :key="index"
            :to="tab.path"
            class="dashboard-activities-index-page__wrapper"
        >
            <div class="dashboard-activities-index-page__item">
                <div class="dashboard-activities-index-page__image-and-title">
                    <img :src="tab.icon" :alt="tab.name" />
                    {{ tab.name }}
                </div>
                <div class="dashboard-activities-index-page__link">
                    <p>
                        <span>{{ $lang.pages.dashboardIndex.see }}</span> <span>{{ tab.name }}</span>
                    </p>
                    <div class="dashboard-activities-index-page__link-icon">
                        <i class="far fa-long-arrow-right"></i>
                    </div>
                </div>
            </div>
        </NuxtLink>
    </section>
</template>

<script lang="ts" setup>
const { $lang, $assets } = useNuxtApp()

const mainTabs = [
    {
        name: $lang.pages.dashboardIndex.recent_activity,
        pos: 1,
        path: `/${$lang.routes.dashboard}/${$lang.routes.activity}`,
        icon: $assets.illustration.dashSummary,
    },
    {
        name: $lang.pages.dashboardIndex.discounts_published,
        pos: 2,
        path: `/${$lang.routes.dashboard}/${$lang.routes.discounts}`,
        icon: $assets.illustration.dashOffer,
    },
    {
        name: $lang.pages.dashboardIndex.discounts_saved,
        pos: 3,
        path: `/${$lang.routes.dashboard}/${$lang.routes.saved}`,
        icon: $assets.illustration.dashboardDiscountsSaved,
    },
    {
        name: $lang.pages.dashboardIndex.chats,
        pos: 4,
        path: `/${$lang.routes.dashboard}/${$lang.routes.chats}`,
        icon: $assets.illustration.dashChats,
    },
    {
        name: $lang.pages.dashboardIndex.settings,
        pos: 6,
        path: `/${$lang.routes.dashboard}/${$lang.routes.settings}`,
        icon: $assets.illustration.dashboardSettings,
    },
]

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.dashboardIndex.title_seo
const description = $lang.pages.dashboardIndex.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.dashboard-activities-index-page {
    @apply grid grid-cols-1 gap-3 p-4 lg:grid-cols-2;
    &__wrapper {
        @apply block rounded-3xl bg-[#F4F4F9] bg-dashboard-tab bg-right bg-no-repeat;
    }
    &__item {
        @apply flex h-28 flex-col justify-between p-4 lg:h-[10.8rem];
    }
    &__image-and-title {
        @apply flex flex-row gap-x-2 gap-y-4 text-base font-semibold text-[#404040] lg:max-w-xs lg:flex-col lg:text-lg;
        img {
            @apply h-8 w-8 lg:h-11 lg:w-11;
        }
    }
    &__link {
        @apply flex gap-1.5 self-end text-sm font-semibold text-site-primary hover:brightness-110;
        p {
            @apply underline underline-offset-4;
        }
        span:last-child {
            @apply lowercase;
        }
        &-icon {
            @apply pt-0.5 text-center;
        }
    }
}
</style>
